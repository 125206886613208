@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100%;
  margin: 0 auto;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav {
  width: 100%;
  height: 3em;
  background-color: rgb(37, 39, 53);
  display: flex;
  justify-content: flex-start;
  padding: 1em;
}

.burger-menu {
  height: 50%;
  width: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.burger-bar {
  width: 2em;
  height: 0.2em;
  background-color: #9E9E9E;
  border-radius: 0.5em;
}

.menu {
  width: 100%;
  height: 1000px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  z-index: -1;
}

.hidden {
  display: none;
}

.visible {
  display: inherit;
}

.burger-bar.clicked:nth-child(1){
  transform: rotate(45deg) translate(0.25em, 0.25em);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2){
  transform: scale(0.1);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3){
  transform: rotate(135deg) translate(-0.5em, 0.5em);
  transition: ease-out 0.5s;
}

.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}